.nav_link {
  margin-left: 0px;
  background-color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav_link:hover {
  color: #fff;
  background-color: #171a1dea;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  margin-left: 10px;
  padding: 15px;
  font-weight: bold;
  border-radius: 6px;
}
.icon_link {
  margin-left: 10px;
  margin-right: 5px;
}
